import { IScreen } from './iScreen';
import P5 from 'p5';
import Player from './player';
import Entity from './entities/entity';
import GameMap from './gameMap';
import MainMenuScreen from './mainMenuScreen';
import { ObjectToDraw } from './types';
import SpriteAnimationRenderer from './spriteAnimationRenderer';

class GameScreen implements IScreen{
    gameMap: GameMap;
    player: Player;

    touchStartX: number = 0;
    touchStartY: number = 0;
    isTouching: boolean = false;

    constructor(private p5: P5){
        this.player = new Player(this.p5);
        this.gameMap = new GameMap(this.p5, this.player);
    }

    setup(){

    }

    draw(buffer: P5.Graphics){
        // create a list to store objects that will be drawn later
        let objectsToDraw: ObjectToDraw[] = [];

        this.gameMap.update();
        this.player.update(this.gameMap);
        for(let entity of entities){
            entity.update();
        }

        this.gameMap.render(objectsToDraw);
        this.player.render(objectsToDraw);
        for(let entity of entities){
            entity.render(objectsToDraw);
        }
        
        // Draw all objects in the sorted order
        objectsToDraw.sort((a, b) => a.z - b.z);
        for(let obj of objectsToDraw) {
            buffer.image(obj.image, obj.x, obj.y);
        }

        this.player.renderUI();

        // draw touch control indicator

        if(this.isTouching){
            let startPosX = this.touchStartX/pixelSize-buffer.width/2;
            let startPosY = this.touchStartY/pixelSize-buffer.height/2;

            const touch = this.p5.touches[0] as any;
            let endPosX = touch.x/pixelSize-buffer.width/2;
            let endPosY = touch.y/pixelSize-buffer.height/2;

            let angle = Math.atan2(endPosY - startPosY, endPosX - startPosX);
            let distance = this.p5.dist(startPosX, startPosY, endPosX, endPosY)/4;
            if(distance > 25){
                distance = 25;
            }

            buffer.fill(255, 100);
            buffer.ellipse(startPosX, startPosY, 80, 80);
            buffer.ellipse(startPosX + this.p5.cos(angle) * distance, startPosY + this.p5.sin(angle) * distance, 30, 30);
        }


        if(showDebug){
            let debugText = `Frame rate: ${this.p5.frameRate().toFixed(2)} fps\n`;
            debugText += `Frame render time: ${renderTimeLast.toFixed(2)} / 16ms\n`;
            debugText += this.player.getDebugText();
            debugText += this.gameMap.getDebugText();
            debugText += `Entities: ${entities.length}\n`;

            buffer.textAlign(buffer.LEFT, buffer.TOP);
            buffer.fill(255);
            buffer.textSize(11);
            buffer.noStroke();
            buffer.text(debugText, -buffer.width/2+10, -buffer.height/2+10);
        }
    }   

    keyPressed(){
        if(this.p5.keyCode === this.p5.ESCAPE){
            iScreen = new MainMenuScreen(this.p5);
            return;
        }else if(this.p5.key === '?'){
            showDebug = !showDebug;
        }

        //console.log(`key: ${this.p5.key}, keyCode: ${this.p5.keyCode}`);

        this.player.keyPressed();
    }   

    keyReleased(){
        this.player.keyReleased();
    }

    mousePressed(){
        
    }

    mouseReleased(){
        
    }

    touchStarted(){
        const touch = this.p5.touches[0] as any;
        this.touchStartX = touch.x;
        this.touchStartY = touch.y;
        this.isTouching = true;
    }

    touchEnded(){
        this.player.goToDirection(-1);
        this.isTouching = false;
    }

    touchMoved(): void {
        // control the player with touch
        const touch = this.p5.touches[0] as any;
        
        // calculate the angle between the start and current touch position
        const angle = Math.atan2(touch.y - this.touchStartY, touch.x - this.touchStartX);

        //determine the direction based on the angle (8 directions)
        let direction = this.p5.int(this.p5.map(angle + 0.3, 0, this.p5.TWO_PI, 0, 8) + 6) % 8;

        //move the player
        this.player.goToDirection(direction);
    }

}

export default GameScreen;