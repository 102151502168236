import { ObjectToDraw } from './../types';
import P5 from 'p5';
import Entity from './entity';
import SpriteAnimationRenderer from '../spriteAnimationRenderer';
import Player from '../player';

class Chicken implements Entity {
    x: number;
    y: number;
    vx: number = 0;
    vy: number = 0;
    gx: number;
    gy: number;
    speed: number;
    currentDirection: number;
    state: string;
    startWalkingTime: number;
    isDead: boolean;
    animIdle: SpriteAnimationRenderer;
    animRunning: SpriteAnimationRenderer;
    animWalking: SpriteAnimationRenderer;

    constructor(private p5 : P5, private player: Player, sx: number, sy: number) {
        this.x = sx;
        this.y = sy;

        this.gx = 0;
        this.gy = 0;
        this.speed = 5;
        this.currentDirection = 7;
        this.state = 'idle';
        this.startWalkingTime = p5.millis();
        this.isDead = false;
        
        this.animIdle = new SpriteAnimationRenderer(p5);
        this.animIdle.load('assets/entities/chicken/idle');

        this.animRunning = new SpriteAnimationRenderer(p5);
        this.animRunning.load('assets/entities/chicken/running');

        this.animWalking = new SpriteAnimationRenderer(p5);
        this.animWalking.load('assets/entities/chicken/walking');
    }

    init() {
        console.log('Chicken initialized');
    }

    update() {
        let tmpX = this.x;
        let tmpY = this.y;

        let distance = this.p5.dist(this.player.x, this.player.y, this.x, this.y)
        if(distance < 100){
            this.state = 'running';
        }else if(distance > 200 && this.state == 'running'){
            this.state = 'walking';
            this.gx = this.x + this.p5.int(this.p5.random(-256, 256));
            this.gy = this.y + this.p5.int(this.p5.random(-256, 256));
        }

        if(this.state == 'running'){
            let angle = this.p5.atan2(this.player.y-this.y, this.player.x-this.x);
            this.vx = this.p5.int(-this.p5.cos(angle) * this.speed);
            this.vy = this.p5.int(-this.p5.sin(angle) * this.speed);
            this.currentDirection = (this.p5.int(this.p5.map(this.p5.atan2(this.vy, this.vx), -this.p5.PI, this.p5.PI, 0, 7))+3) % 8;
        }else if(this.state == 'walking'){
            let distanceToGoal = this.p5.dist(this.x, this.y, this.gx, this.gy);

            if (distanceToGoal > 20) {
                // Reset velocities
                this.vx = 0;
                this.vy = 0;

                // Check x direction
                if (Math.abs(this.x - this.gx) < 5) {
                    this.vx = 0;
                } else if (this.x < this.gx) {
                    this.vx = 1;
                } else {
                    this.vx = -1;
                }

                // Check y direction
                if (Math.abs(this.y - this.gy) < 5) {
                    this.vy = 0;
                } else if (this.y < this.gy) {
                    this.vy = 1;
                } else {
                    this.vy = -1;
                }

                // Calculate the current direction
                this.currentDirection = (this.p5.int(this.p5.map(this.p5.atan2(this.vy, this.vx), -this.p5.PI, this.p5.PI, 0, 7)) + 3) % 8;

                // Update position
                this.x += this.vx;
                this.y += this.vy;
            }else{
                this.startWalkingTime = this.p5.int(this.p5.millis() + this.p5.random(5000, 20000));
                this.state = 'idle';
                this.vx = 0;
                this.vy = 0;
            }
        }else if (this.state == 'idle'){
            this.vx = 0;
            this.vy = 0;

            if(this.p5.millis() > this.startWalkingTime){
                this.state = 'walking';
                this.gx = this.x + this.p5.int(this.p5.random(-100, 100));
                this.gy = this.y + this.p5.int(this.p5.random(-100, 100));
            }
        }

        //check if the mouse is over the chicken
        let posX = this.p5.int((this.p5.mouseX/pixelSize) - (buffer.width/2));
        let posY = this.p5.int((this.p5.mouseY/pixelSize) - (buffer.height/2));

        if(this.p5.dist(posX, posY, this.x-this.player.x, this.y-this.player.y) < 30){
            if(this.p5.mouseIsPressed){
                this.player.score += 10;
                this.isDead = true;
            }
        }

        tmpX += this.vx;
        tmpY += this.vy;

        if(true){ //TODO, check if the mob is colliding with obstacle
            this.x = tmpX;
            this.y = tmpY;
        }
    }

    render(objectsToDraw: ObjectToDraw[]) {
        let posOnScreen = {x: this.x-this.player.x, y: this.y-this.player.y};

        if(posOnScreen.x < -buffer.width/2-64 || posOnScreen.x > buffer.width/2+64 || posOnScreen.y < -buffer.height/2-64 || posOnScreen.y > buffer.height/2+64){
            return;
        }

        if(showDebug){
            buffer.stroke(255);
            buffer.strokeWeight(1);
            buffer.line(this.x-this.player.x, this.y-this.player.y, this.gx-this.player.x, this.gy-this.player.y);

            buffer.noStroke();
            buffer.fill(255, 0, 0);
            buffer.ellipse(this.gx-this.player.x, this.gy-this.player.y, 10, 10);

            buffer.fill(255);
            buffer.textAlign(this.p5.CENTER, this.p5.TOP);
            let time = this.p5.int((this.startWalkingTime-this.p5.millis())/1000);
            if(time < 0){
                time = 0;
            }
            buffer.text(this.state + ": " + time + "s", this.x-this.player.x, this.y-this.player.y+10);
        }

        buffer.noStroke();
        buffer.fill(0, 100);
        buffer.ellipse(this.x-this.player.x, this.y-this.player.y, 30, 15);

        
        if(this.state == 'running'){
            this.animRunning.render(objectsToDraw, this.x-this.player.x, this.y-this.player.y, this.currentDirection);
        }else if(this.state == 'walking'){
            this.animWalking.render(objectsToDraw, this.x-this.player.x, this.y-this.player.y, this.currentDirection);
        }else if (this.state == 'idle'){
            this.animIdle.render(objectsToDraw, this.x-this.player.x, this.y-this.player.y, this.currentDirection);
        }
    }

    isToBeRemoved() {
        return this.isDead;
    }

    kill() {
        this.isDead = true;
    }
}

export default Chicken;