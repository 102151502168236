import P5 from "p5";
import MainMenuScreen from "./mainMenuScreen";
import GameScreen from "./gameScreen";
import Player from "./player";

//Image buffer
pixelSize = 2; 
isMobile = false;
showDebug = false;
renderTime = 0;
renderTimeLast = 0;
entities = [];
//player = null;

const sketch = (p5 : P5) => {
    p5.preload = () => {
        iScreen = new GameScreen(p5);
        //player = new Player(p5);
    }

    p5.setup = () => {
        p5.createCanvas(p5.windowWidth, p5.windowHeight);
        isMobile = detectMobile();
        let cols = p5.floor(p5.width / pixelSize);
        let rows = p5.floor(p5.height / pixelSize);
        buffer = p5.createGraphics(cols, rows);
        buffer.translate(p5.int(buffer.width / 2), p5.int(buffer.height / 2));

        iScreen.setup();
        
        //use window resize event to resize canvas
        p5.windowResized();
    }

    p5.draw = () => {
        renderTime = p5.millis();
        buffer.background(0);

        // Update screen
        iScreen.draw(buffer);

        //draw cursor
        if(!isMobile){
            drawCursor();
        }
        
        // if(p5.mouseIsPressed){
        //     // shake effect
        //     let shake = 10;
        //     p5.translate(p5.random(-shake, shake), p5.random(-shake, shake));
        // }

        // Draw buffer to the main canvas, scaled up
        p5.noSmooth();
        p5.image(buffer, 0, 0, p5.width, p5.height);
        renderTime = p5.millis() - renderTime;

        // p5.textAlign(p5.LEFT, p5.TOP);
        // p5.fill(255);
        // p5.text("Render time: " + p5.int(renderTime) + "ms / 16.67ms", 10, 10);
        renderTimeLast = renderTime;
    }

    p5.windowResized = () => {
        p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
        let min = p5.min(p5.windowWidth, p5.windowHeight);
        if(min < 960){
            pixelSize = 1;
        }else if (min < 2000){
            pixelSize = 2;
        }else if (min < 4000){
            pixelSize = 3;
        }else{
            pixelSize = 4;
        }
        let cols = p5.floor(p5.width / pixelSize);
        let rows = p5.floor(p5.height / pixelSize);
        buffer = p5.createGraphics(cols, rows);
        buffer.translate(p5.int(buffer.width / 2), p5.int(buffer.height / 2));
    }

    p5.keyPressed = () => {
        iScreen.keyPressed();
    }
        

    p5.keyReleased = () => {
        iScreen.keyReleased();
    }

    p5.mousePressed = () => {
        iScreen.mousePressed();
    }

    p5.mouseReleased = () => {
        iScreen.mouseReleased();
    }

    p5.touchStarted = () => {
        iScreen.touchStarted();
    }

    p5.touchEnded = () => {
        iScreen.touchEnded();
    }

    p5.touchMoved = () => {
        iScreen.touchMoved();
        return false;
    }

    function drawCursor(){
        let cursorSize = 8;
        let posX = p5.int((p5.mouseX/pixelSize) - (buffer.width/2));
        let posY = p5.int((p5.mouseY/pixelSize) - (buffer.height/2));
        buffer.stroke(255);
        buffer.strokeWeight(2);
        buffer.line(posX-cursorSize, posY, posX+cursorSize, posY);
        buffer.line(posX, posY-cursorSize, posX, posY+cursorSize);
    }

    function detectMobile() : boolean{
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);
        return isMobileDevice;
    }
}

new P5(sketch);