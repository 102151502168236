import { ObjectToDraw } from './types';
import P5 from 'p5';
import SpriteAnimationRenderer from "./spriteAnimationRenderer";
import GameMap from './gameMap';

class Player{
    x: number;
    y: number;
    vx: number;
    vy: number;
    speed: number;

    keyW: boolean;
    keyA: boolean;
    keyS: boolean;
    keyD: boolean;

    currentDirection: number;
    isRunning: boolean;
    enteredPortal: boolean;

    health: number;
    score: number;

    animIdle: SpriteAnimationRenderer;
    animRunning: SpriteAnimationRenderer;

    waypointX : number;
    waypointY : number;
    waypointShow : boolean;

    constructor(private p5: P5){
        this.x = 256;
        this.y = 256;
        this.vx = 0;
        this.vy = 0;
        this.speed = 4;
        this.health = 100;
        this.score = 0;
        this.currentDirection = 0;
        this.isRunning = false;
        this.enteredPortal = false;

        this.waypointX = 5376;
        this.waypointY = 2816;
        this.waypointShow = true;

        this.keyW = false;
        this.keyA = false;
        this.keyS = false;
        this.keyD = false;

        this.animIdle = new SpriteAnimationRenderer(this.p5);
        this.animRunning = new SpriteAnimationRenderer(this.p5);
        this.animIdle.load("/assets/player/idle");
        this.animRunning.load("/assets/player/running");
    }

    // goToDirection(dir: number){
    //     // 8 directions, angle = 45 degrees
    //     // 0 = down, 2 = left, 4 = up, 6 = right, -1 for stop

    //     if(dir == -1){
    //         this.keyW = false;
    //         this.keyA = false;
    //         this.keyS = false;
    //         this.keyD = false;
    //     }else if(dir == 0){
    //         this.keyW = false;
    //         this.keyA = false;
    //         this.keyS = true;
    //         this.keyD = false;
    //     }else if(dir == 2){
    //         this.keyW = false;
    //         this.keyA = true;
    //         this.keyS = false;
    //         this.keyD = false;
    //     }
    // }

    goToDirection(dir: number) {
        // Reset all keys to false initially
        this.keyW = false;
        this.keyA = false;
        this.keyS = false;
        this.keyD = false;
    
        // Handle direction using a switch statement
        switch (dir) {
            case -1:
                // No need to change anything, all keys are already false
                break;
            case 0:
                this.keyS = true;
                break;
            case 2:
                this.keyA = true;
                break;
            case 4:
                this.keyW = true;
                break;
            case 6:
                this.keyD = true;
                break;
            case 1:
                this.keyS = true;
                this.keyA = true;
                break;
            case 3:
                this.keyA = true;
                this.keyW = true;
                break;
            case 5:
                this.keyW = true;
                this.keyD = true;
                break;
            case 7:
                this.keyD = true;
                this.keyS = true;
                break;
            default:
                console.warn(`Unknown direction: ${dir}`);
                break;
        }
    }

    update(gameMap: GameMap){
        let tmpX = this.x;
        let tmpY = this.y;

        if(this.keyA && this.keyW){
            this.currentDirection = 3;
            this.vx = -this.speed;
            this.vy = -this.speed;
        }else if(this.keyA && this.keyS){
            this.currentDirection = 1;
            this.vx = -this.speed;
            this.vy = this.speed;
        }else if(this.keyD && this.keyW){
            this.currentDirection = 5;
            this.vx = this.speed;
            this.vy = -this.speed;
        }else if(this.keyD && this.keyS){
            this.currentDirection = 7;
            this.vx = this.speed;
            this.vy = this.speed;
        }else if(this.keyW){
            this.currentDirection = 4;
            this.vx = 0;
            this.vy = -this.speed;
        }else if(this.keyA){
            this.currentDirection = 2;
            this.vx = -this.speed;
            this.vy = 0;
        }else if(this.keyS){
            this.currentDirection = 0;
            this.vx = 0;
            this.vy = this.speed;
        }else if(this.keyD){
            this.currentDirection = 6;
            this.vx = this.speed;
            this.vy = 0;
        }else{
            this.vx = 0;
            this.vy = 0;
        }

        tmpX += this.vx;
        tmpY += this.vy;
        
        if(this.vx != 0 || this.vy != 0){
            this.isRunning = true;
        }else{
            this.isRunning = false;
        }

        let currentChunk = {x: this.p5.floor(tmpX / gameMap.chunkSize), y: this.p5.floor(tmpY / gameMap.chunkSize)};
        let chunkKey = currentChunk.x + "_" + currentChunk.y;
        let offset = 5;

        if (gameMap.chunks[chunkKey]) {
            let chunk = gameMap.chunks[chunkKey]
            if (!chunk.isEmpty) {
                if(chunk.walls){
                    for(let wall of chunk.walls){
                        let wallX = (currentChunk.x * gameMap.chunkSize) - tmpX + wall.x;
                        let wallY = (currentChunk.y * gameMap.chunkSize) - tmpY + wall.y;

                        let wallX2 = wallX - offset;
                        let wallY2 = wallY - offset;
                        let wallW2 = wall.w + offset*2;
                        let wallH2 = wall.h + offset*2;

                        if(0 > wallX2 && 0 < wallX2 + wallW2 && 0 > wallY2 && 0 < wallY2 + wallH2){
                            // Calculate distances to each side
                            let leftDist = Math.abs(wallX2);
                            let rightDist = Math.abs(wallX2 + wallW2);
                            let topDist = Math.abs(wallY2);
                            let bottomDist = Math.abs(wallY2 + wallH2);

                            // Find the closest side
                            let minDist = Math.min(leftDist, rightDist, topDist, bottomDist);

                            if (minDist === leftDist) {
                                // Move player to the left side of the wall
                                tmpX = currentChunk.x * gameMap.chunkSize + wall.x - offset;
                            } else if (minDist === rightDist) {
                                // Move player to the right side of the wall
                                tmpX = currentChunk.x * gameMap.chunkSize + wall.x + wall.w + offset;
                            } else if (minDist === topDist) {
                                // Move player to the top side of the wall
                                tmpY = currentChunk.y * gameMap.chunkSize + wall.y - offset;
                            } else if (minDist === bottomDist) {
                                // Move player to the bottom side of the wall
                                tmpY = currentChunk.y * gameMap.chunkSize + wall.y + wall.h + offset;
                            }
                        }
                    }

                    let isPlayerInPortal = false;

                    for(let portal of chunk.portals){
                        let portalX = (currentChunk.x * gameMap.chunkSize) - tmpX + portal.x;
                        let portalY = (currentChunk.y * gameMap.chunkSize) - tmpY + portal.y;

                        if(0 > portalX && 0 < portalX + portal.w && 0 > portalY && 0 < portalY + portal.h){
                            isPlayerInPortal = true;
                            if(!this.enteredPortal){
                                tmpX = portal.tx;
                                tmpY = portal.ty;
                                console.log("Entered portal", portal.to);
                                console.log("Player position", this.x, this.y);
                                gameMap.loadWorld(portal.to);
                            }
                        }
                    }
                    this.enteredPortal = isPlayerInPortal;
                }
            }
        }


        if(true){ //TODO, check if the player is colliding with obstacle
            this.x = tmpX;
            this.y = tmpY;
        }
    }

    render(objectsToDraw: ObjectToDraw[]){
        if(showDebug){
            buffer.noStroke();
            buffer.fill(255, 0, 0);
            buffer.ellipse(0-this.x, 0-this.y, 4, 4); // Draw center of the map

            buffer.stroke(255);
            buffer.line(-20, 0, 20, 0);
            buffer.line(0, -20, 0, 20);
        }

        buffer.noStroke();
        buffer.fill(0, 100);
        buffer.ellipse(0, 0, 50, 30);

        if(this.isRunning){
            this.animRunning.render(objectsToDraw, 0, 0, this.currentDirection);
        }else{
            this.animIdle.render(objectsToDraw, 0, 0, this.currentDirection);
        }
    }

    renderUI(){

        if(this.waypointShow){
            let xOnScreen = this.waypointX - this.x;
            let yOnScreen = this.waypointY - this.y;

            //check if it is on the screen render area
            if(xOnScreen > -buffer.width/2 && xOnScreen < buffer.width/2 && yOnScreen > -buffer.height/2 && yOnScreen < buffer.height/2){
                buffer.stroke(255);
                buffer.strokeWeight(2);
                buffer.noFill();
                buffer.ellipse(xOnScreen, yOnScreen, 10, 10);

                buffer.noStroke();
                buffer.fill(255);
                buffer.textSize(10);
                buffer.textAlign(buffer.CENTER, buffer.BOTTOM);
                buffer.text("Waypoint", xOnScreen, yOnScreen - 10);
            }else{
                // position for off-screen indicator
                let halfWidth = buffer.width / 2;
                let halfHeight = buffer.height / 2;
                let offset = 15;

                let xBoundary = halfWidth - offset;
                let yBoundary = halfHeight - offset;

                let intersectX, intersectY;

                // Check intersections with screen bounds with offset
                if (Math.abs(xOnScreen / yOnScreen) > Math.abs(xBoundary / yBoundary)) {
                    intersectX = xBoundary * Math.sign(xOnScreen);
                    intersectY = intersectX * yOnScreen / xOnScreen;
                } else {
                    intersectY = yBoundary * Math.sign(yOnScreen);
                    intersectX = intersectY * xOnScreen / yOnScreen;
                }

                buffer.stroke(255);
                buffer.strokeWeight(2);
                buffer.noFill();
                buffer.ellipse(intersectX, intersectY, 10, 10); // Display off-screen indicator

            }
        }
    }

    getDebugText(){
        return "Player position: " + this.x + ", " + this.y + "\n";
    }

    keyPressed(){
        // Use WASD to move the player
        let lowerKey = this.p5.key.toLowerCase();
        if(lowerKey == 'w'){
            this.keyW = true;
        }
        if(lowerKey == 'a'){
            this.keyA = true;
        }
        if(lowerKey == 's'){
            this.keyS = true;
        }
        if(lowerKey == 'd'){
            this.keyD = true;
        }
    }

    keyReleased(){
        // Use WASD to move the player
        let lowerKey = this.p5.key.toLowerCase();
        if(lowerKey == 'w'){
            this.keyW = false;
        }
        if(lowerKey == 'a'){
            this.keyA = false;
        }
        if(lowerKey == 's'){
            this.keyS = false;
        }
        if(lowerKey == 'd'){
            this.keyD = false;
        }
    }

    
}

export default Player;