import GameScreen from './gameScreen';
import { IScreen } from './iScreen';
import P5 from 'p5';

class MainMenuScreen implements IScreen{
    option : number = 0;
    options : string[] = ["Start", "Options", "Exit"];

    constructor(private p5: P5){

    }

    setup(){
        
    }

    draw(buffer: P5.Graphics){
        
        if(!isMobile){
            //display options:
            buffer.textAlign(buffer.CENTER, buffer.CENTER);
            buffer.fill(255);
            buffer.textSize(24);
            buffer.noStroke();
            for(let i = 0; i < this.options.length; i++){
                if(i == this.option){
                    buffer.fill(205 + (this.p5.sin(this.p5.frameCount / 5.0) * 25), 0, 0);
                    buffer.text("> " + this.options[i] + " <", 0, (i - 1) * 32);
                }else{
                    buffer.fill(255);
                    buffer.text(this.options[i], 0, (i - 1) * 32);
                }
            }
        }else{
            buffer.textAlign(buffer.CENTER, buffer.CENTER);
            buffer.fill(255);
            buffer.textSize(15);
            buffer.noStroke();
            buffer.text("Touch is not supported.\nPress any key to dimmis", 0, 0);
        }
    }

    keyPressed(){
        if(isMobile){
            isMobile = false;
            return;
        }
        if(this.p5.keyCode == this.p5.UP_ARROW || this.p5.key.toLowerCase() === 'w'){
            this.option--;
            if(this.option < 0){
                this.option = this.options.length - 1;
            }

        }else if(this.p5.keyCode == this.p5.DOWN_ARROW || this.p5.key.toLowerCase() === 's'){
            this.option++;
            if(this.option >= this.options.length){
                this.option = 0;
            }
        }else if(this.p5.keyCode == this.p5.ENTER){
            if(this.option == 0){
                console.log("Start Game");
                iScreen = new GameScreen(this.p5);
                iScreen.setup();
            }else if(this.option == 1){
                console.log("Options");
                //iScreen = new OptionsScreen();
            }else if(this.option == 2){
                console.log("Exit");
            }
        }
    }

    keyReleased(){
        
    }

    mousePressed(){
        
    }

    mouseReleased(){
        
    }

    touchStarted(){
        
    }

    touchEnded(){
        
    }

    touchMoved(): void {
        
    }

}

export default MainMenuScreen;