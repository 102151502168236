import Chicken from './entities/chicken';
import Player from './player';
import { ObjectToDraw } from './types';
import seedrandom from 'seedrandom';
import P5 from 'p5';
import { randomArrayElementRNG, randomIntRNG, randomRNG } from './helpers';

class GameMap{
    chunkSize: number = 512;
    renderDistance: number = 2; // Number of chunks to load around the player
    unloadChunkDistance: number = 4; // Distance beyond which chunks are unloaded
    worldName: string;
    currentPlayerChunk: {x: number, y: number} = {x: 1000000, y: 1000000};

    chunks: {[key: string]: any} = {};
    chunkQueue: {[key: string]: boolean} = {};
    tileImages: {[key: string]: any} = {};
    tileImagesQueue: {[key: string]: boolean} = {};
    objects: {[key: string]: any} = {};
    objectsQueue: {[key: string]: boolean} = {};
    worldData: any;
    mapRNG: seedrandom;

    constructor(private p5 : P5, private player : Player){
        this.mapRNG = seedrandom(0);
        this.loadWorld("world");
    }

    loadWorldData(){
        this.p5.loadJSON(`assets/worlds/${this.worldName}/world.json`, (data) => {
            console.log(`Data for world.json loaded!`);
            console.log(data);

            this.worldData = data;
            this.loadTileImage(data.defaultTile);
        }, () => {
            this.worldData = null;
            console.error(`Failed to load JSON for world.json`);
        });
    }

    loadWorld(worldToLoad: string){
        if(this.worldName != worldToLoad){
            this.worldName = worldToLoad;
            
            entities = [];
            this.chunks = {};
            this.chunkQueue = {};
            this.currentPlayerChunk = {x: 1000000, y: 1000000};
            this.worldData = null;
            this.loadWorldData();
        }
    }

    loadChunk(x: number, y: number){
        let chunkName = `${x}_${y}`;
        
        if(this.chunks[chunkName] || this.chunkQueue[chunkName]){
            return;
        }

        this.chunkQueue[chunkName] = true; // Mark as loading

        this.p5.loadJSON(`assets/worlds/${this.worldName}/chunk_${chunkName}.json`, (data) => {
            console.log(`Data for chunk ${chunkName}.json loaded!`);

            this.loadTileImage(data.tileImage);
            for(let object of data.objects){
                this.loadObject(object.name);
            }

            this.chunks[chunkName] = data;
            delete this.chunkQueue[chunkName]; // Remove loading mark
        }, () => {
            // Error callback for loadJSON
            delete this.chunkQueue[chunkName]; // Remove loading mark on error
            // Fill the chunk with default data
            if(this.worldData){
                this.chunks[chunkName] = {
                    tileImage: this.worldData.defaultTile,
                    objects: [],
                    portals: [],
                    entities: [],
                    walls: []
                };

                this.mapRNG = seedrandom(chunkName);

                for(let i=0; i<10; i++){
                    // add few random objects (tree, rock, grass, plant)
                    let rndX = randomIntRNG(this.mapRNG, 0, this.chunkSize);
                    let rndY = randomIntRNG(this.mapRNG, 0, this.chunkSize);
                    let rndObj = randomArrayElementRNG(this.mapRNG, this.worldData.naturalObjects);
                    this.chunks[chunkName].objects.push({name: rndObj, x: rndX, y: rndY}); // push is not a function!
                }

                if(randomRNG(this.mapRNG, 0, 1) < 0.25){
                    let rndX = randomIntRNG(this.mapRNG, 0, this.chunkSize);
                    let rndY = randomIntRNG(this.mapRNG, 0, this.chunkSize);
                    let rndEntity = randomArrayElementRNG(this.mapRNG, this.worldData.naturalEntities);
                    this.chunks[chunkName].entities.push({x: rndX, y: rndY, name: rndEntity});
                }

                this.loadTileImage(this.chunks[chunkName].tileImage);
                for(let object of this.chunks[chunkName].objects){
                    this.loadObject(object.name);
                }


                if(this.chunks[chunkName].entities){
                    let chunkX_px = x * this.chunkSize;
                    let chunkY_px = y * this.chunkSize;

                    for(let entity of this.chunks[chunkName].entities){
                        let px = chunkX_px + entity.x;
                        let py = chunkY_px + entity.y;
                        if(entity.name == "chicken"){
                            entities.push(new Chicken(this.p5, this.player, px, py));
                        }
                    }
                }
            }else{
                this.chunks[chunkName] = {
                    tileImage: "default",
                    objects: [],
                    portals: [],
                    entities: [],
                    walls: []
                };
            }

            console.error(`Failed to load JSON for chunk ${this.worldName}/chunk_${chunkName}.json`);
        });
        
    }

    unloadChunk(x: number, y: number){
        let chunkName = `${x}_${y}`;
        if(this.chunks[chunkName]){
            delete this.chunks[chunkName];
        }
    }

    loadObject(name: string){
        if(this.objects[name] || this.objectsQueue[name]){
            //console.log(`Object ${name} already loaded or in queue`);
            return;
        }

        console.log(`Loading object ${name}`);

        this.objectsQueue[name] = true; // Mark as loading

        this.p5.loadJSON(`assets/objects/${name}.json`, (data) => {
            console.log(`Data for ${name}.json loaded!`);

            this.p5.loadImage(`assets/objects/${name}.png`, (img) => {
                console.log(`Image for ${name}.png loaded!`);

                if(data.animated){
                    console.log(`Object ${name} is animated!`);
                    let images : P5.Image[] = [];
                    let frames = img.width / data.boundary.w;
                    for(let i=0; i<frames; i++){
                        let x = i * data.boundary.w;
                        let y = 0;
                        images[i] = img.get(x, y, data.boundary.w, data.boundary.h);
                    }
                    this.objects[name] = {
                        data: data,
                        image: images
                    };
                    console.log(`Object ${name} is ${this.objects[name].image.length} frames long`);
                }else{
                    this.objects[name] = {
                        data: data,
                        image: img
                    };
                }

                
                delete this.objectsQueue[name]; // Remove loading mark
            }, () => {
                // Error callback for loadImage
                delete this.objectsQueue[name]; // Remove loading mark on error
                console.error(`Failed to load image for ${name}.png`);
            });
        }, () => {
            // Error callback for loadJSON
            delete this.objectsQueue[name]; // Remove loading mark on error
            console.error(`Failed to load JSON for ${name}.json`);
        });
    }

    loadTileImage(name: string){
        if(this.tileImages[name] || this.tileImagesQueue[name]){
            return;
        }

        this.tileImagesQueue[name] = true; // Mark as loading

        this.p5.loadImage(`assets/tiles/${name}.png`, (img) => {
            console.log(`Tile image for ${name}.png loaded!`);
            this.tileImages[name] = img;
            delete this.tileImagesQueue[name]; // Remove loading mark
        }, () => {
            // Error callback for loadImage
            delete this.tileImagesQueue[name]; // Remove loading mark on error
            console.error(`Failed to load image for ${name}.png`);
        });
    }

    update(){
        let currentChunk = {x: this.p5.floor(this.player.x / this.chunkSize), y: this.p5.floor(this.player.y / this.chunkSize)};

        if(currentChunk.x != this.currentPlayerChunk.x || currentChunk.y != this.currentPlayerChunk.y){
            if(this.worldData){
                this.currentPlayerChunk = currentChunk;
                console.log("Player chunk changed", currentChunk);
                this.currentPlayerChunkChanged();
            }
        }
    }

    currentPlayerChunkChanged(){
        this.loadVisibleChunks();
        this.unloadDistantChunks();
    }

    loadVisibleChunks(){
        let currentChunk = {x: this.p5.floor(this.player.x / this.chunkSize), y: this.p5.floor(this.player.y / this.chunkSize)};
        
        for (let i = -this.renderDistance; i <= this.renderDistance; i++) {
            for (let j = -this.renderDistance; j <= this.renderDistance; j++) {
                let chunkX = currentChunk.x + i;
                let chunkY = currentChunk.y + j;
                this.loadChunk(chunkX, chunkY);
            }
        }
    }

    unloadDistantChunks(){
        let currentChunk = {x: this.p5.floor(this.player.x / this.chunkSize), y: this.p5.floor(this.player.y / this.chunkSize)};
        let keysToRemove: {x: number, y: number}[] = [];
      
        for (let key in this.chunks) {
            let [chunkX, chunkY] = key.split('_').map(Number);
            if (this.p5.abs(chunkX - currentChunk.x) > this.unloadChunkDistance || this.p5.abs(chunkY - currentChunk.y) > this.unloadChunkDistance) {
                keysToRemove.push({x: chunkX, y: chunkY});
            }
        }

        keysToRemove.forEach(key => {
            console.log("Unloading chunk", key);
            this.unloadChunk(key.x, key.y);
        });
    }


    render(objectsToDraw: ObjectToDraw[]){
        let currentChunk = {x: this.p5.floor(this.player.x / this.chunkSize), y: this.p5.floor(this.player.y / this.chunkSize)};
        
        //let distance = unloadChunkDistance;
        let distance = this.renderDistance;
        for (let i = -distance; i <= distance; i++) {
            for (let j = -distance; j <= distance; j++) {
                let chunkX = currentChunk.x + i;
                let chunkY = currentChunk.y + j;
                let chunkKey = chunkX + "_" + chunkY;
        
                if (this.chunks[chunkKey]) {
                    if (!this.chunks[chunkKey].isEmpty) {
                        this.renderChunk(chunkX, chunkY, this.chunks[chunkKey], objectsToDraw);
                    }
                }
            }
        }

        if(showDebug){
            let distance2 = this.unloadChunkDistance;
            for (let i = -distance2; i <= distance2; i++) {
                for (let j = -distance2; j <= distance2; j++) {
                    let chunkX = currentChunk.x + i;
                    let chunkY = currentChunk.y + j;
                    let posX = chunkX * this.chunkSize - this.player.x;
                    let posY = chunkY * this.chunkSize - this.player.y;

                    buffer.stroke(255, 25);
                    buffer.strokeWeight(1);
                    buffer.noFill();
                    buffer.rect(posX, posY, this.chunkSize, this.chunkSize);

                    buffer.fill(255);
                    buffer.stroke(0);
                    buffer.strokeWeight(3);
                    buffer.textSize(10);
                    buffer.textAlign(buffer.LEFT, buffer.TOP);
                    buffer.text(`${chunkX}, ${chunkY}`, posX + 10, posY + 10);
                }
            }
        }
    }

    renderChunk(x: number, y: number, chunk: any, objectToDraw: ObjectToDraw[]){
        let posX = x * this.chunkSize - this.player.x;
        let posY = y * this.chunkSize - this.player.y;

        buffer.fill(50);
        buffer.noStroke();
        buffer.rect(posX, posY, this.chunkSize, this.chunkSize);

        if(chunk.tileImage && this.tileImages[chunk.tileImage]){
            buffer.image(this.tileImages[chunk.tileImage], posX, posY);
        }

        // render objects if any
        if(chunk.objects && chunk.objects.length > 0){
            for(let object of chunk.objects){
                if(this.objects[object.name]){
                    let obj = this.objects[object.name];
                    if(obj.image){
                        let objX = posX + object.x - obj.data.center.x;
                        let objY = posY + object.y - obj.data.center.y;
                        let objZ = posY + object.y + obj.data.zindex;
                        if(obj.data.animated){
                            //console.log("Animated object", obj);
                            let img = obj.image[this.p5.int(this.p5.frameCount / 4.0) % obj.image.length];
                            objectToDraw.push({image: img, x: objX, y: objY, z: objZ});
                        }else{
                            objectToDraw.push({image: obj.image, x: objX, y: objY, z: objZ});
                        }
                    }
                }
            }
        }

        //render walls (debug)
        if(showDebug){
            if(chunk.walls && chunk.walls.length > 0){
                for(let wall of chunk.walls){
                    buffer.fill(255, 0, 0, 50);
                    buffer.stroke(255, 0, 0, 100);
                    buffer.strokeWeight(1);
                    buffer.rect(posX + wall.x, posY + wall.y, wall.w, wall.h);
                }
            }
            if(chunk.portals && chunk.portals.length){
                for(let portal of chunk.portals){
                    buffer.fill(255, 0, 255, 50);
                    buffer.stroke(255, 0, 255, 100);
                    buffer.strokeWeight(1);
                    buffer.rect(posX + portal.x, posY + portal.y, portal.w, portal.h);
                    buffer.fill(255);
                    buffer.noStroke();
                    buffer.textSize(11);
                    buffer.textAlign(this.p5.CENTER, this.p5.CENTER);
                    buffer.text(`to: ${portal.to}\nx: ${portal.tx}\ny: ${portal.ty}`, posX + portal.x + (portal.w/2), posY + portal.y + (portal.h/2));
                }
            }
        }
    }

    getDebugText(){
        let txt = "World name: " + this.worldName + "\n";
        txt += "Loaded chunks: " + Object.keys(this.chunks).length + "\n";
        txt += "Loaded tile images: " + Object.keys(this.tileImages).length + "\n";
        txt += "Loaded objects: " + Object.keys(this.objects).length + "\n";
        return txt;
    }
}

export default GameMap;