import P5 from 'p5';
import { ObjectToDraw, SpriteAnimCache } from './types';

class SpriteAnimationRenderer{
    static cache: { [key: string]: SpriteAnimCache } = {};
    static cacheQueue: { [key: string]: boolean } = {};

    animationOffset : number;
    path : string;

    constructor(private p5: P5){
        this.animationOffset = this.p5.random(1000);
    }

    load(pathToLoad : string){
        this.path = pathToLoad;
        if(!SpriteAnimationRenderer.cache[pathToLoad] && !SpriteAnimationRenderer.cacheQueue[pathToLoad]){
            SpriteAnimationRenderer.cacheQueue[pathToLoad] = true;

            this.p5.loadJSON(`${pathToLoad}.json`, (data) => {
                console.log(`Data for ${pathToLoad}.json loaded!`);

                this.p5.loadImage(`${pathToLoad}.png`, (img) => {
                    console.log(`Image for ${pathToLoad}.png loaded!`);

                    let images : P5.Image[][] = [];
                            // Load the sprites
                            for (let i = 0; i < data.rotations; i++) {
                                images[i] = [];
                                for (let j = 0; j < data.frames; j++) {
                                    // Get frame from spritesheet
                                    let x = j * data.frameWidth;
                                    let y = i * data.frameHeight;
                                    images[i][j] = img.get(x, y, data.frameWidth, data.frameHeight);
                                    // console.log(`Frame loaded!, x ${x} y ${y} w ${frameWidth} h ${frameHeight}`);
                                }
                            }

                    let cachedData : SpriteAnimCache = {
                        centerX : data.centerX,
                        centerY : data.centerY,
                        frameWidth : data.frameWidth,
                        frameHeight : data.frameHeight,
                        framesCount : data.frames,
                        rotationCount : data.rotations,
                        images: images
                    };
    
                    SpriteAnimationRenderer.cache[pathToLoad] = cachedData;

                    delete SpriteAnimationRenderer.cacheQueue[pathToLoad]; // Remove loading mark
                }, () => {
                    // Error callback for loadImage
                    delete SpriteAnimationRenderer.cacheQueue[pathToLoad]; // Remove loading mark on error
                    console.error(`Failed to load image for ${pathToLoad}.png`);
                });
            }, () => {
                // Error callback for loadJSON
                delete SpriteAnimationRenderer.cacheQueue[pathToLoad]; // Remove loading mark on error
                console.error(`Failed to load JSON for ${pathToLoad}.json`);
            });
        }
        // else{
        //     if(SpriteAnimationRenderer.cacheQueue[pathToLoad]){
        //         console.log(`Data for ${pathToLoad} is already loading!`);
        //     }
        //     if(SpriteAnimationRenderer.cache[pathToLoad]){
        //         console.log(`Data for ${pathToLoad} already loaded!`);
        //     }
        // }
    }


    render(objectsToDraw: ObjectToDraw[], x: number, y: number, rotation: number) {
        let cache = SpriteAnimationRenderer.cache[this.path];
        if (!cache) {
            return;
        }

        rotation = rotation % cache.rotationCount;
        let currentFrame = this.p5.floor(((this.p5.frameCount / 4.0) + this.animationOffset) % cache.framesCount);
        let currentImage = cache.images[rotation][currentFrame];
        objectsToDraw.push({ image: currentImage, x: x - cache.centerX, y: y - cache.centerY, z: y });
    }
}

export default SpriteAnimationRenderer;