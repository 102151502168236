import seedrandom from 'seedrandom';

export function randomRNG(rng: seedrandom, min: number, max: number) {
    return (rng() * (max - min)) + min;
}

export function randomIntRNG(rng: seedrandom, min: number, max: number) {
    return Math.floor(randomRNG(rng, min, max));
}

//helper function to get random object from an array
export function randomArrayElementRNG(rng: seedrandom, array: any[]) {
    return array[randomIntRNG(rng, 0, array.length)];
}